<template>
  <v-dialog :value="load"
            @keydown.esc="toggleLoginForm"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="($vuetify.breakpoint.sm ? '70%' :
              ($vuetify.breakpoint.md ?
              '60%' :
              '35%'))"
            transition="scale-transition"
            persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <span class="headline">{{ $t('login.form__header') }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="toggleLoginForm"
               icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-alert v-model="alert.display"
                     :type="alert.type"
                     :color="alert.color"
                     dismissible>
              {{ alert.text }}
            </v-alert>
            <v-text-field v-model="user.email"
                          :label="$t('common.field__email-header')"
                          prepend-inner-icon="email"
                          :rules="[v => !!v || $t('common.field__text-validation-error'),
                                   v => email_pattern.test(v) || $t('common.field__email-validation-error')]"
                          autofocus clearable></v-text-field>
            <v-text-field v-model="user.password"
                          :label="$t('common.field__password-header')"
                          @keyup.enter="AuthenticateUser"
                          prepend-inner-icon="keyboard"
                          :append-icon="visible ? 'visibility_off' : 'visibility'"
                          @click:append="visible=!visible"
                          :type="visible ? 'text' : 'password'"
                          :rules="[v => !!v|| $t('common.field__text-validation-error'),
                                   v => (v && v.length>=6) || $t('common.field__password-validation-error')]"
                          clearable
            ></v-text-field>

            <v-layout row justify-end>
              <v-btn @click="loadRecoveryForm"
                     class="text-none font-weight-light"
                     color="orange"
                     small round text flat right>
                {{ $t("login.text__recover-password") }}
              </v-btn>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="AuthenticateUser"
                     :loading="processing"
                     :disabled="!email_pattern.test(user.email) || !(user.password && user.password.length>5)"
                     color="primary"
                     block dark text>
                Login
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LoginDialog",
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
    processing: {
      get() {
        return this.$store.state.processing;
      },
      set(value) {
        return this.$store.commit("SET_PROCESSING_STATUS", value);
      },
    },
  },
  data: () => ({
    visible: false,
    // Object that represents an user.
    user: {
      id: "",
      name: "",
      email: "",
      password: "",
      logged_in: false,
    },
    // Pattern to recognize a valid email address.
    // eslint-disable-next-line no-useless-escape
    email_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    valid: false,
  }),
  methods: {
    /**
     * Log an user into the site.
     * @constructor
     */
    AuthenticateUser() {
      // Shows the loading pin while processing data.
      this.processing = true;
      this.alert.display = false;
      // Sends user info to backend to login.
      // eslint-disable-next-line no-undef
      axios.post(`${axios.defaults.baseURL}/user/auth`,
          JSON.stringify({
            email: this.user.email,
            password: this.user.password,
            // eslint-disable-next-line no-undef
            url: axios.defaults.baseURL
          })).then(response => {
        this.$store.dispatch("authenticateUser", {
          id: response.data.id,
          email: response.data.email
        });
      }).catch(err => {
        // If credentials are not met, shows the error alert.
        this.processing = false;
        this.user.password = "";
        this.alert.text = this.$t("common.dialog__login-unregistered");
        this.alert.type = "error";
        this.alert.display = true;
        console.log("error", err.response)
      });
    },
    /**
     * Emits event to close the login form.
     */
    toggleLoginForm() {
      this.$refs.form.reset()
      this.alert.display = false;
      this.$store.dispatch("setDialogStatus", {
        name: "login", value: false
      });
    },
    loadRecoveryForm() {
      this.toggleLoginForm();
      this.$store.dispatch('setDialogStatus', {
        name: 'password_recover',
        value: true
      })
    },
  }
};
</script>

<style scoped>

</style>
